@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap-sprockets";
@import "custom-bootstrap";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "icons";
@import "sidebar";
@import "toolbar";
@import "fileupload";

body {
  color: #333;
}

body, html {
  height: 100%;
}

a,
a:active,
a:focus,
a:hover,
input:focus {
  outline: 0;
  text-decoration: none;
}

.container-fluid {
  padding: 0 30px;
}

.no-border {
  border: none;
}

.w-100 {
  width: 100%;
}

/***** BACKGROUNDS *****/

%bg-white {
  background-color: #FFF;
}

%bg-blue {
  background-color: #3AB4C1;
}

%bg-grey {
  background-color: #EDEDED;
}

/***** ERRORS *****/

.error-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/***** BUTTONS *****/

.btn-red {
  @include button-variant(#FFF, #EF2B31, #EF2B31);
}

.btn-blue {
  @include button-variant(#FFF, #3ab4c1, #3ab4c1);
}

/****** TITLES *****/

h2.title {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 20px 0;
  line-height: 40px;
  border-bottom: solid 2px #3ab4c1;
  display: inline-block;
  vertical-align: top;
}

/***** BOOK LIST *****/

.book-list {
  @extend %bg-white;
  border: solid 1px #c6c6c6;
  overflow: hidden;
  padding: 20px 0;
  margin: 0;
}

.book-list .book {
  float: left;
  width: 20%;
  padding: 0 15px;
}

.book-list .book .book-thumb {
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0 0 15px 0;
  border: solid 2px transparent;
}

.book-thumb img {
  width: 100%;
}

.book-list .book .book-caption h3 {
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.book-caption h3 a {
  color: #333;
}

.book-caption .author {
  display: block;
  font-size: 12px;
  margin: 0 0 5px 0;
}

/***** DEFAULT LIST *****/

.default-list {
  @extend %bg-white;
  border: solid 1px #c6c6c6;
  overflow: hidden;
  padding: 20px;
  margin: 0;
}

.default-list {
  .default-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .default-table thead tr {
    border-bottom: 2px solid #ddd;
  }

  .default-table tbody tr {
    border-top: 1px solid #ddd;
    margin-bottom: 10px;
  }

  .default-table thead th,
  .default-table tbody td {
    padding: 10px;
  }
}

.default-pagination {
  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
  }

  .pagination a,
  .pagination span {
    font-size: 12px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    border: 1px solid #3ab4c1;
    margin: 0 2px;
    border-radius: 0 !important;
  }

  .pagination a {
    background: #3ab4c1;
    color: #fff;
  }

  .pagination .active span {
    background: #FFF;
    color: #3ab4c1;
  }
}

/***** TOOLBOX *****/

.toolbox {
  padding-top: 8px;
}

/***** FORM PANEL *****/

.form-panel {
  width: 100%;
  background: #fff;
  padding: 20px;
  border: solid 1px #ccc;
}

.form-panel {
  input[type=text], input[type=mail], textarea, select {
    width: 100%;
    border: solid 1px #ccc;
    padding: 5px 10px;
  }

  select {
    height: 32px;
  }
}

/***** BOOK DETAIL *****/

.cover {
  display: inline-block;
  max-width: 300px;
}

.cover img {
  width: 100%;
}

.picture-thumb {
  display: inline-block;
}