body {
  overflow-x: hidden;
}

/* Toggle Styles */

#wrapper {
  height: 100%;
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow: hidden;
  @extend %bg-blue;
  border-right: solid 1px #175c5c;
  box-shadow: 0 0 20px #175c5c;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 15px 0;
  @extend %bg-grey;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0 20px;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  font-family: $droid-sans;
  display: block;
  text-decoration: none;
  font-size: 16px;
  line-height: 35px;
  font-weight: 100;
  color: #333;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav li span {
  margin-left: 10px;
}

.sidebar-nav > .sidebar-brand {
  font-size: 18px;
  line-height: 60px;
  border-bottom: solid 1px #56bfca;
}

.sidebar-nav > .sidebar-brand a {
  display: block;
  padding: 20px 0;
  border-bottom: solid 1px #329da8;
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

@media(min-width:768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
    overflow-y: hidden;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    padding: 20px 0;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}