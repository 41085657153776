.icons{
  display: inline-block;
  width: 24px; height: 24px;
  background-image: url('../images/menu-icons.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.icon-topbooks{
  background-position: 0px 0;
}
.icon-discover{
  background-position: -138px 0;
}
.icon-categories{
  background-position: -165px 0;
}
.icon-reading{
  background-position: -107px 0;
}
.icon-favorite{
  background-position: -78px 0;
}
.icon-history{
  background-position: -52px 0;
}
.icon-shelves{
  background-position: -27px 0;
}

a:hover .icon-topbooks{
  background-position: 0px -24px;
}
a:hover .icon-discover{
  background-position: -138px -24px;
}
a:hover .icon-categories{
  background-position: -165px -24px;
}
a:hover .icon-reading{
  background-position: -107px -24px;
}
a:hover .icon-favorite{
  background-position: -78px -24px;
}
a:hover .icon-history{
  background-position: -52px -24px;
}
a:hover .icon-shelves{
  background-position: -27px -24px;
}