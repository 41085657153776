#toolbar {
  width: 100%;
  min-width: 320px;
  height: 102px;
  padding: 30px 0;
  @extend %bg-grey;
}

.search {
  position: relative;
  color: #333;
  font-size: 16px;
}

.search input {
  width: 250px;
  height: 40px;

  background: transparent;
  border: solid 1px #333;
  border-radius: 40px;
}

.search input { text-indent: 32px;}
.search .fa-search {
  position: absolute;
  top: 10px;
  right: 10px;
}

#menu-toggle {
  border: none;
  background: none;
  font-size: 30px;
  height: 40px;
  padding: 0;
  color: #333;
}

#menu-toggle:hover,
#menu-toggle:active,
#menu-toggle:focus {
  text-decoration: none;
}