/***** CUSTOM BOOTSTRAP START *****/

// Google Fonts
@import 'https://fonts.googleapis.com/css?family=Droid+Sans:100,400,700';

// Font Path
$icon-font-path: './vendor/bootstrap/fonts/';

// Font Variables
$droid-sans: 'Droid Sans', sans-serif;

// Navbar Variables
$navbar-height: 82px !default;
$navbar-default-bg: #3ab4c1;
$navbar-default-border: #3ab4c1;
$navbar-default-link-active-bg: #3ab4c1;

$navbar-default-color: #FFF;
$navbar-default-link-color: #FFF;
$navbar-default-brand-color: #FFF;
$navbar-default-link-hover-color: #EF2B31;

$navbar-border-radius: 0;
$navbar-margin-bottom: 0;

/***** CUSTOM BOOTSTRAP END *****/